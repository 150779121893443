$f-fallback-heading: 'serif';
$f-playfair-regular: 'playfair_displayregular', $f-fallback-heading;
$f-playfair-bold: 'playfair_displaybold', $f-fallback-heading;
$f-playfair-black: 'playfair_displayblack', $f-fallback-heading;

$f-fallback-content: 'sans-serif';
$f-roboto-thin: 'robotothin', $f-fallback-content;
$f-roboto-light: 'robotolight', $f-fallback-content;
$f-roboto-regular: 'roboto', $f-fallback-content;
$f-roboto-medium: 'robotomedium', $f-fallback-content;
$f-roboto-bold: 'robotobold', $f-fallback-content;
$f-roboto-black: 'robotoblack', $f-fallback-content;
