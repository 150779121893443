/*
    Generalities
*/

body {
	background: #fff;
	color: #3a3a3a;
	font-family: $f-roboto-regular;
	font-size: 14px;
	margin: 0;
	padding: 0;
}

body.of-hidden {
	overflow: hidden;
}

h1,h2 {
	font-family: $f-playfair-bold;
}

h3 {
	font-family: $f-roboto-thin;
}

p + {

	h2,h3 {
		margin-top: 3rem;
	}
}

a {
	color: #00739d;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

figure {
	margin: 0;
	padding: 0;
}

#next-svg-icon,#prev-svg-icon {
	fill: transparent;
	height: 24px;
	stroke: #ad172b;
}

#prev-svg-icon {
	margin-right: 20px;
	transform: rotate( -180deg );
}

#next-svg-icon {
	margin-left: 20px;
}
