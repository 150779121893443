/*
    Fonts
*/

//PLAYFAIR DISPLAY

@font-face {
	font-family: 'playfair_displayregular';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/playfairdisplay-regular-webfont.woff2' ) format( 'woff2' ), url( '../fonts/playfairdisplay-regular-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'playfair_displaybold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/playfairdisplay-bold-webfont.woff2' ) format( 'woff2' ), url( '../fonts/playfairdisplay-bold-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'playfair_displayblack';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/playfairdisplay-black-webfont.woff2' ) format( 'woff2' ), url( '../fonts/playfairdisplay-black-webfont.woff' ) format( 'woff' );
}

/* ROBOTO */

@font-face {
	font-family: 'robotothin';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-thin-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-thin-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'robotolight';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-light-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-light-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'roboto';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-regular-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-regular-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'robotomedium';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-medium-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-medium-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'robotobold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-bold-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-bold-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'robotoblack';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-black-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-black-webfont.woff' ) format( 'woff' );
}

/* BRANDON */

@import url('../fonts/Brandon-Printed-W90-One-Shadow.woff');

@import url('../fonts/Brandon-Printed-W90-One-Shadow.woff2');

@import url('../fonts/Brandon-Printed-W90-One-Shadow.ttf');

@import url('../fonts/BrandonText-BlackItalic.woff');

@import url('../fonts/BrandonText-BlackItalic.woff2');

@import url('../fonts/BrandonText-BlackItalic.ttf');

@import url('../fonts/BrandonText-Bold.woff');

@import url('../fonts/BrandonText-Bold.woff2');

@import url('../fonts/BrandonText-Bold.ttf');

@import url('../fonts/BrandonText-LightItalic.woff');

@import url('../fonts/BrandonText-LightItalic.woff2');

@import url('../fonts/BrandonText-LightItalic.ttf');

@import url('../fonts/BrandonText-Medium.woff');

@import url('../fonts/BrandonText-Medium.woff2');

@import url('../fonts/BrandonText-Medium.ttf');

@import url('../fonts/BrandonText-MediumItalic.woff');

@import url('../fonts/BrandonText-MediumItalic.woff2');

@import url('../fonts/BrandonText-MediumItalic.ttf');

@import url('../fonts/BrandonText-Regular.woff');

@import url('../fonts/BrandonText-Regular.woff2');

@import url('../fonts/BrandonText-Regular.ttf');

@font-face {
	font-family: 'Brandon-shadow';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/brandon-printed-w90-one-shadow.woff2') format('woff2'),
		url('../fonts/Brandon-Printed-W90-One-Shadow.woff') format('woff'),
		url('../fonts/Brandon-Printed-W90-One-Shadow.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon-black-italic';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-BlackItalic.woff2') format('woff2'),
		url('../fonts/BrandonText-BlackItalic.woff') format('woff'),
		url('../fonts/BrandonText-BlackItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon-bold';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-Bold.woff2') format('woff2'),
		url('../fonts/BrandonText-Bold.woff') format('woff'),
		url('../fonts/BrandonText-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon-light-italic';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-LightItalic.woff2') format('woff2'),
		url('../fonts/BrandonText-LightItalic.woff') format('woff'),
		url('../fonts/BrandonText-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon-medium';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-Medium.woff2') format('woff2'),
		url('../fonts/BrandonText-Medium.woff') format('woff'),
		url('../fonts/BrandonText-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon-medium-italic';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-MediumItalic.woff2') format('woff2'),
		url('../fonts/BrandonText-MediumItalic.woff') format('woff'),
		url('../fonts/BrandonText-MediumItalic.ttf') format('truetype');;
}

@font-face {
	font-family: 'Brandon-regular';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/BrandonText-Regular.woff2') format('woff2'),
		url('../fonts/BrandonText-Regular.woff') format('woff'),
		url('../fonts/BrandonText-Regular.ttf') format('truetype');;
}

/* KAPRA */

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.woff');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.woff2');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.ttf');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.woff');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.woff2');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.ttf');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.woff');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.woff2');

@import url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.ttf');

@font-face {
	font-family: 'Kapra-medium';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.woff2') format('woff2'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.woff') format('woff'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Kapra-medium-semibold';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.woff2') format('woff2'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.woff') format('woff'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Medium-Rd.ttf') format('truetype');
}

@font-face {
	font-family: 'Kapra-bold';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.woff2') format('woff2'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.woff') format('woff'),
		url('../fonts/Typoforge-Studio-Kapra-Neue-Pro-Bold.ttf') format('truetype');
}