.site-secondary-navigation {

	.ssn__list {

		.ssn__list__item {
			position: relative;

			&:not( :last-child )::after {
				background-color: rgba( color( 'gray' ), 0.25 );
				content: '';
				display: block;
				height: 12px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY( -50% );
				width: 1px;
			}

			&:last-child {

				.ssn__list__link {
					padding-right: 0;
				}
			}
		}

		.ssn__list__link {
			color: rgba( $white, 1 );
			font-family: $f-roboto-medium;
			font-size: 0.8rem;
			padding: 0.5rem 1.5rem;
			text-transform: uppercase;

			@include media-breakpoint-up(md) {
				color: $dark;
			}

			&:hover {
				background-color: $white;
				color: $dark;
			}
		}
	}
}