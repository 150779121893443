
/*
    Page header
*/

.page__header {
	background: url( /dist/img/page-bg.jpg ) no-repeat center center/cover;
	height: 70vh;
	max-height: 600px;
	min-height: 600px;
	padding-bottom: 40px;
	position: relative;
}

@include media-breakpoint-up(md) {

	.page__header {
		padding-bottom: 0;
	}

}

/*
    Pge header heading block
*/

.page__header__heading-block {
	background: rgba( 255, 255, 255, 0.9 );
	box-shadow: 0 0 0 14px rgba( 209, 224, 229, 0.9 );
	left: 50%;
	margin: 0 auto;
	max-width: 500px;
	padding: 40px;
	position: absolute;
	text-align: center;
	top: 80px;
	transform: translateX( -50% );
	width: 90%;
}

.page__header__heading-block__title {
	font-family: $f-playfair-bold;
	font-size: 2.5rem;
	margin-bottom: 1rem;
}