/*
* Featured Products
*/

.featured-products__wrapper {
	background: $light;
}

.featured-products {
	background: #fff;
	box-shadow: 0 -1rem 3rem 0 rgba( $black, 0.25 );
	padding: 20px;
	position: relative;
	text-align: center;
	top: 0;
	z-index: 2;
}

@include media-breakpoint-up(md) {

	.home .featured-products {
		top: -100px;
	}

}

.featured-products__inner {
	border: 1px solid rgba( $primary, 0.25 );
	padding-bottom: 40px;
}

.featured-products__title {
	font-size: 40px;
	margin-top: 2rem;

	span {
		align-items: center;
		display: inline-flex;
		justify-content: center;
	}
}

.featured-products__list {
	list-style: none;
	margin: 40px auto;
	padding: 0;
}

.fp__list__item {

	.card {
		border: none;
		color: #3a3a3a;
		display: block;
		padding: 0 20px;
		text-decoration: none;
	}

	.card--product {
		height: 100%;
	}

	.card__body {
		height: 100%;
	}

	.card__title {
		font-family: $f-roboto-bold;
		font-size: 1rem;
	}

	.card__price {
		font-family: $f-roboto-black;
		font-size: 1.2rem;

		.unit {
			font-family: $f-roboto-regular;
			font-size: 1rem;
		}
	}
}

@include media-breakpoint-up(md) {

	.fp__list__item {

		&[class^='col-'],
		&[class*=' col-'] {
			flex: 0 0 20%;
			max-width: 20%;
			width: 20%;
		}
	}

}

/* SLICK */

@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: $secondary !default;
$slick-dot-color-active: $primary !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 30px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {

	@if function-exists(image-url) {

		@return image-url($url);

	}

	@else {

		@return url($slick-loader-path + $url);

	}

}

@function slick-font-url($url) {

	@if function-exists(font-url) {

		@return font-url($url);

	}

	@else {

		@return url($slick-font-path + $url);

	}

}

/* Slider */

.slick-slider {

	@include media-breakpoint-up(sm) {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.slick-active + .slick-active {

	.fp__list__item {
		border-left: solid 1px $gray-200;
	}
}

.slick-track {
	align-items: stretch;
	display: flex;
}

.slick-slide {
	height: auto;

	> div {
		align-items: stretch;
		display: flex;
		height: 100%;
	}
}

.slick-list {

	.slick-loading & {
		background: #fff slick-image-url( 'ajax-loader.gif' ) center center no-repeat;
	}
}

/* Icons */

@if $slick-font-family == 'slick' {

	@font-face {
		font-family: 'slick';
		font-style: normal;
		font-weight: normal;
		src: slick-font-url( 'slick.eot' );
		src: slick-font-url( 'slick.eot?#iefix' ) format( 'embedded-opentype' ), slick-font-url( 'slick.woff' ) format( 'woff' ), slick-font-url( 'slick.ttf' ) format( 'truetype' ), slick-font-url( 'slick.svg#slick' ) format( 'svg' );
	}

}

/* Arrows */

.slick-prev,
.slick-next {
	background: transparent;
	border: none;
	color: transparent;
	cursor: pointer;
	display: block;
	font-size: 0px;
	height: 20px;
	line-height: 0px;
	outline: none;
	padding: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translate( 0, -50% );
	-ms-transform: translate( 0, -50% );
	transform: translate( 0, -50% );
	width: 20px;

	&:hover,&:focus {
		background: transparent;
		color: transparent;
		outline: none;

		&::before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled::before {
		opacity: $slick-opacity-not-active;
	}

	&::before {
		color: $slick-arrow-color;
		font-family: $slick-font-family;
		font-size: 20px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 1;
		opacity: $slick-opacity-default;
	}
}

.slick-prev {
	left: 5px;
	z-index: 10;

	[dir='rtl'] & {
		left: auto;
		right: -25px;
	}

	&::before {
		background: url( '../img/icons/icon-arrow-left.svg' ) center center no-repeat;
		content: '';
		display: block;
		height: 27px;
		width: 17px;

		[dir='rtl'] & {
			background: url( '../img/icons/icon-arrow-left.svg' ) center center no-repeat;
			content: '';
		}
	}
}

.slick-next {
	right: 5px;
	z-index: 10;

	[dir='rtl'] & {
		left: -25px;
		right: auto;
	}

	&::before {
		background: url( '../img/icons/icon-arrow-right.svg' ) center center no-repeat;
		content: '';
		display: block;
		height: 27px;
		width: 17px;

		[dir='rtl'] & {
			background: url( '../img/icons/icon-arrow-right.svg' ) center center no-repeat;
			content: '';
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 50px;
}

.slick-dots {
	bottom: -2.5rem;
	display: block;
	left: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	width: 100%;

	li {
		cursor: pointer;
		display: inline-block;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		position: relative;
		width: 20px;

		button {
			background: transparent;
			border: 0;
			color: transparent;
			cursor: pointer;
			display: block;
			font-size: 0;
			height: 20px;
			line-height: 0;
			outline: none;
			padding: 5px;
			width: 20px;

			&:hover,&:focus {
				outline: none;

				&::before {
					opacity: $slick-opacity-on-hover;
				}
			}

			&::before {
				color: $slick-dot-color;
				content: $slick-dot-character;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				height: 20px;
				left: 0;
				line-height: 20px;
				opacity: $slick-opacity-not-active;
				position: absolute;
				text-align: center;
				top: 0;
				width: 20px;
			}
		}

		&.slick-active button::before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}

.paging-info {
	color: $gray-700;
	font-size: 0.9rem;

	@include media-breakpoint-up(sm) {
		display: none;
	}
}
