/*
   Lead text
*/

.lead {

	@extend .border-bottom;
	font-family: $f-roboto-light;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
}