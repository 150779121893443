/*
    Site header
*/

.site-header {
	background: #fff;

	.site-logo {
		min-width: 100px;
	}
}