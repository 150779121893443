/*
* Recipe detail page
*/

.recipe-details {
	background-color: inherit;
}

.rd__informationn--preparation {
	counter-reset: number;

	.list-group-item {
		align-items: stretch;
		display: flex;

		&::before {
			content: counter( number );
			counter-increment: number;
			display: block;
			padding-right: 0.5rem;
		}
	}
}