/*
    Store address header
*/

.store__selection {

	.ss__address {
		color: rgba( black, 0.5 );
		font-size: 0.9rem;

		a {
			color: $primary;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}