/*
    Newsletter Cta Section
*/

.newsletter-cta {
	background: #bcd1d9 url( /dist/img/triangle-pattern.png ) repeat;
	padding: 60px 0 320px 0;
	position: relative;
	text-align: center;

	@include media-breakpoint-up(md) {
		padding: 150px 0;
		top: -180px;
	}

	.btn {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

.nc__title {
	font-size: 40px;
	margin: 0 auto;

	+ p {
		margin-top: 0;
	}
}

.nc__description {
	font-size: 16px;
}

.modal-legal {
	color: $gray-700;
	font-size: 0.8rem;
	margin: 1rem 0;
	text-align: center;
}