.main-menu {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.nav-link {
		background-color: transparent;
		color: $white !important;
		font-family: $f-roboto-regular;
		padding-bottom: 1rem;
		padding-top: 1rem;
		text-transform: uppercase;
		transition: all ease 0.5s;

		&:hover {
			background-color: darken( $primary, 10% );
		}
	}
}

.main-menu-modal {
	background-color: $primary;
	font-size: 1.6rem;
	height: 100vh;
	width: 100vw;

	.nav-item {
		display: block;
		width: 100%;
	}

	.nav-link {
		background-color: transparent;
		color: $white;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		text-transform: uppercase;
		transition: all ease 0.5s;

		&:hover {
			background-color: darken( $primary, 10% );
		}
	}

	.main-menu {

		.nav-link {
			font-family: $f-roboto-black;
		}
	}

	.secondary-menu {

		.nav-link {
			font-family: $f-roboto-medium;
			font-size: 1.2rem;
		}
	}

	.form-control {
		padding: 1.5rem;
	}

	hr {
		background-color: $white;
		width: 15%;
	}

	.close {
		opacity: 1;
		position: absolute;
		right: 1rem;
		top: 1rem;
		z-index: 10;
	}
}