/*
    Site footer
*/

.site-footer {
	background: #fff;
	position: relative;
}


.site-footer__inner {
	padding-bottom: 30px;
	padding-top: 30px;
	position: relative;

	@include media-breakpoint-up(md) {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	&::before {
		border-top: 1px solid #c9dae1;
		content: '';
		display: block;
		left: 16px;
		margin: 0 auto;
		position: absolute;
		right: 16px;
		top: 0;
		width: calc( 100% - 32px );
	}
}

.footer__left {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@include media-breakpoint-down(md) {
		order: 1;
	}
}

footer__right {

	@include media-breakpoint-down(md) {
		order: 0;
	}
}

.footer__nav {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
	width: 100%;
}

.footer__nav__item {
	padding: 0.5rem 0;
	text-align: center;

	@include media-breakpoint-up(md) {
		display: inline-block;
		padding: 0;
		text-align: left;
	}
}

.footer__nav__item__link {

	@include media-breakpoint-up(md) {
		border-right: solid 1px $gray-500;
		padding-left: 1rem;
		padding-right: 1rem;

		.footer__nav__item:first-child & {
			padding-left: 0;
		}
	}
}

.footer__nav__item--provinces {

	@include media-breakpoint-up(md) {
		padding-left: 1rem;
	}

	.footer__nav__item {

		&:last-child {

			.footer__nav__item__link {
				border-right: 0;
			}
		}
	}
}

.footer__social-list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	width: 100%;

	@include media-breakpoint-down(lg) {
		margin-bottom: 1.5rem;
	}

	@include media-breakpoint-up(lg) {
		text-align: right;
	}

	a:hover {
		text-decoration: none;
	}
}

.footer__social-list__item {
	display: inline-block;
	padding: 7px;
}

.footer__social-list__item__link {
	align-items: center;
	background: #3a3a3a;
	border-radius: 50%;
	display: flex;
	height: 40px;
	justify-content: center;
	line-height: 40px;
	transition: all 200ms ease;
	width: 40px;
}

.footer__social-list__item__link i {
	color: #fff;
	font-size: 22px;
	vertical-align: middle;
}

.footer__social-list__item__link:hover {
	background: #212121;
}

.footer__social-list__item__link:focus {
	background: #141414;
}

.copyright {
	color: $gray-600;
	margin-right: 20px;
	padding: 0;

	@include media-breakpoint-down(md) {
		font-size: 0.9rem;
		margin-right: 0;
		margin-top: 1rem;
		text-align: center;
		width: 100%;
	}
}