
/*
   card
*/


.card__image__container {
	min-height: 160px;
}

//FIX IE11

.card-img-top,.card-img-link {
	min-height: 1px;
}

card-img-link {
	display: flex;
}