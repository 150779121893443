
/*
   Carousel
*/

.carousel {
	margin-bottom: 4rem;
}

.carousel-inner {
	max-height: 22.5rem;
}
