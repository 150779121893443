
/*
   jumbotron
*/


.jumbotron {
	background-position: center;
	background-size: cover;
	position: relative;

	.page__content--product & {

		&::before {
			background: linear-gradient( rgba( black, 0 ), rgba( black, 0.8 ) );
			bottom: 0;
			content: ' ';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
