/*
* Stores page
*/

.stores {

	.s__map {

		td {
			border-top: 0;
		}
	}

	.s__directions {

		div {
			margin: 0 auto;
		}
	}

	.store__phone {
		white-space: nowrap;
	}
}