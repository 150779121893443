/*
* Grocery page
*/

.grocery-list {

	.gl__item {

		.card {
			width: 100%;
		}
	}
}